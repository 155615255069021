import React, { useState, useEffect } from "react";

export const StateCounter = ({ inputData }) => {
  const [totalIssues, setTotalIssues] = useState(0);

  useEffect(() => {
    const newTotalIssues = inputData.reduce(
      (total, data) => (typeof data.amount === 'number' ? total + data.amount : total),
      0
    );
    setTotalIssues(newTotalIssues);
  }, [inputData]);

  const maxAmount = Math.max(...inputData.map((d) => (typeof d.amount === 'number' ? d.amount : 0)));

  return (
    <main>
      <div className="card h-100">
        <div className="card-body pt-0 px-0">          
          {inputData.length > 0 && (
            <ul className="list-group list-group-horizontal w-100">
              {inputData.map((d, i) => (
                <li
                className="list-group-item flex-fill custom-list-item"
                key={i}
                style={{ backgroundColor: d.color }}
                >
                  <div style={{ color: "white" }}>
                    <h1>{typeof d.amount === 'number' ? d.amount : 'Invalid Amount'}</h1>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {inputData.length > 0 ? (
            <div className="container">
              <div className="row">
                {inputData.map((d, i) => (
                  <div className="col-4 px-0" key={i}>
                    <h6 className="mt-2 mb-0">{d.type}</h6>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No data available</p>
          )}
          <h6 className="card-title text-muted mt-4 mb-0 customBoldSoft">STATES LAST 7 DAYS</h6>
        </div>
      </div>
    </main>
  );
};