import { useState } from 'react';
import { auth, auth2, auth3, db, db2, db3 } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Modal, Button, Form } from 'react-bootstrap';
import {
    doc,
    collection,
    setDoc,
} from 'firebase/firestore';

export const Registration = ({ onShow, onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [registrationError, setRegistrationError] = useState(null);

    const usersCollection = collection(db3, 'users');

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    if (onShow) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }

    const registerUser = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth3, email, password);
            const user = userCredential.user;

            const newUser = {
                firstName,
                lastName,
            };

            const userDocRef = doc(usersCollection, user.uid);

            await setDoc(userDocRef, newUser);

            handleClose();

            setRegistrationError(null);
        } catch (error) {
            console.log(error);
            setRegistrationError(error.message);
        }
    }

    return (
        <div>
            <Modal 
                show={onShow} 
                tabIndex="-1"
                onHide={onClose}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicFirstName" className='mb-2'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your first name" onChange={(e) => setFirstName(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicLastName" className='mb-2'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your last name" onChange={(e) => setLastName(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='mb-2'>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        {registrationError && (
                            <p className="text-danger">{registrationError}</p>
                        )}
                        <div className="container mt-3">
                            <div className="row justify-content-end">
                                <Button variant="primary" onClick={registerUser}>
                                    Register
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}