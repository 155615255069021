import { Modal, Button, Form } from 'react-bootstrap';

export const ConfirmationWaterFlush = ({ setConfirmationWaterFlush, onShow, onClose }) => {

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleAction = (confirmation) => {
        setConfirmationWaterFlush(confirmation);
        handleClose();
    };

    if (onShow) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }

    return (
        <div>
            <Modal
                className="clear-background"
                show={onShow}
                tabIndex="-1"
                onHide={handleClose}
                backdrop="static"
                backdropClassName="custom-backdrop-class"
                style={{ top: '150px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label className='mt-2 mb-5'><strong>Are you sure you want to activate a water flush in this unit?</strong></Form.Label>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                        variant="primary"
                        onClick={() => handleAction(true)}
                    >
                        <strong>ACTIVATE WATER FLUSH</strong>
                    </Button>
                    <a
                        href="#"
                        className="btn btn-link text-danger"
                        onClick={() => {
                            handleAction(false)
                        }}
                    >
                        Cancel the operation
                    </a>
                </Modal.Footer>
            </Modal>
        </div>
    );
}