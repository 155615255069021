import { useState, useEffect } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";

export const UnitActivityChart = ({inputData}) => {
    const [totalIssues, setTotalIssues] = useState(0);
    const [active, setActive] = useState(null);
    const width = 100;
    const half = width / 2;
    useEffect(() => {
        const newTotalIssues = inputData.reduce((total, data) => total + data.amount, 0);
        setTotalIssues(newTotalIssues);
    }, [inputData]);
  return <main>
    <div className="card h-100">
        <div className="card-body pt-0">            
            <div className="row">
                <div className="col-6 pt-3 pb-5">
                    <table className="table chart-table mt-2 mb-2">
                        <tbody>
                            {inputData.map((data, i) => (
                                <tr key={i}>
                                    <td className="text-start px-0" style={{paddingTop: '3px', paddingBottom: '3px'}}>
                                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <span 
                                            className='ms-3 me-3'
                                            style={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '5px',
                                            display: 'inline-block',
                                            marginRight: '5px',
                                            backgroundColor: data.color,
                                            }}
                                        ></span>
                                        {data.type}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-6 pt-4">
                    <svg width={width} height={width}>
                        <Group top={half} left={half}>
                            <Pie
                                data={inputData}
                                pieValue={(data) => data.amount}
                                outerRadius={({data}) => {
                                    const size = active && active.type === data.type ? half : half - 3;
                                    return size;
                                }}//half
                                innerRadius={({data}) => {
                                    const size = active && active.type === data.type ? half - 25 : half - 20;
                                    return size;
                                }}
                                cornerRadius={3}
                                padAngle={0.01}
                            >
                                {(pie) => {
                                    return pie.arcs.map((arc, index) => {
                                        const { color, type } = arc.data;
                                        const [centroidX, centroidY] = pie.path.centroid(arc);
                                        return (
                                            <g
                                                key={`arc-${index}`}
                                                onMouseEnter={() => setActive(arc.data)}
                                                onMouseLeave={() => setActive(null)}
                                            >
                                                <path d={pie.path(arc)} fill={color} />
                                            </g>
                                        );
                                    });
                                }}
                            </Pie>
                            {active ? (
                                <Text y={4} style={{ fontSize: '12px', fontWeight: 'bold' }} className="mt-1" textAnchor="middle" fill="#000">
                                    {`${active.amount}/${totalIssues}`}
                                </Text>
                            ) : (
                                <Text y={4} textAnchor="middle" fill="#000">
                                    {totalIssues}
                                </Text>
                            )}
                        </Group>
                    </svg>
                </div>
            </div>
            <h6 className="card-title text-muted mt-2 mb-0 customBoldSoft"><strong>UNIT STATUS</strong></h6>
        </div>
    </div>
  </main>;
}