import { useState, useEffect } from 'react';
import { db, db2, db3, database } from '../firebase';
import {
    getDocs,
    getDoc,
    doc,
    addDoc,
    updateDoc,
    serverTimestamp,
    collection,
    query as fireQuery,
    where,
} from 'firebase/firestore';
import { ref, set } from 'firebase/database';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

export const NewIssue = ({ usersData }) => {
    const kam_eyeIssues = collection(db3, 'kam_eye');
    const issue_types = collection(db3, 'monitoring_issue_types');
    const units = collection(db3, 'units');
    const centers = collection(db3, 'centers');
    const organizations = collection(db3, 'organizations');

    const [unitsData, setUnitsData] = useState([]);
    const [centersData, setCentersData] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);
    const [issueTypesData, setIssueTypesData] = useState([]);
    const [selectedIssueType, setSelectedIssueType] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
    const [selectedCenterId, setSelectedCenterId] = useState(null);
    const [issueStatusSelected, setIssueStatusSelected] = useState([
        { value: "Open", label: "Open" },
        { value: "In progress", label: "In progress" },
    ]);

    const [showFormModal, setShowFormModal] = useState(false);
    const [newIssue, setNewIssue] = useState({
        Assigned: {
            to: '',
        },
        Installation: {
            Dev_type: '',
            ID: '',
            Unit_ID: '',
            Organization: '',
            Center: '',
        },
        Notification: {
            to: [""],
            Automatic: false,
            Status: '',
        },
        Issue: {
            ID: '',
            type: '',
            status: 'Open',
            number: '',
            definition: '',
            message: [""],
        },
    });
    const [validationErrors, setValidationErrors] = useState({
        'Installation.Dev_type': '',
        'Installation.Unit_ID': '',
        'Installation.Organization': '',
        'Installation.Center': '',
        'Issue.type': '',
        'Issue.number': '',
        'Issue.message': '',
    });

    const validateForm = () => {
        const errors = {
            'Installation.Dev_type': '',
            'Installation.Unit_ID': '',
            'Installation.Organization': '',
            'Installation.Center': '',
            'Issue.type': '',
            'Issue.number': '',
            'Issue.message': '',
        };

        if (!newIssue.Installation.Dev_type) {
            errors['Installation.Dev_type'] = 'Device type is required';
        }
        if (!newIssue.Installation.Unit_ID) {
            errors['Installation.Unit_ID'] = 'Unit ID is required';
        }
        if (!newIssue.Installation.Organization) {
            errors['Installation.Organization'] = 'Organization is required';
        }
        if (!newIssue.Installation.Center) {
            errors['Installation.Center'] = 'Center is required';
        }
        if (!newIssue.Issue.type) {
            errors['Issue.type'] = 'Type is required';
        }
        if (newIssue.Issue.number === '') {
            errors['Issue.number'] = 'Issue is required';
        }
        if (!newIssue.Issue.message || newIssue.Issue.message.length === 0) {
            errors['Issue.message'] = 'Message is required';
        }

        setValidationErrors(errors);

        // Check if there are any errors
        return Object.values(errors).every((error) => !error);
    };

    async function transformDocuments() {
        try {
          const snapshot = await getDocs(issue_types);
      
          // Iterate over each document in the collection
          snapshot.forEach(async (document) => {
            const data = document.data();
      
            // Create a new object with the desired structure
            const newData = {
                Actions: '',
                Cause: '',
                assigned: '',
                assignees: [],
                automatic_notification: true,
                comments: '',
                message: data.definition,
                notification_channel: {
                  ntfy: false,
                  slack: true,
                },
                number: data.number,
                origin: 'cartridge',
                type: data.type,
            };
      
            // Update the document in the collection with the new data
            await updateDoc(doc(issue_types, document.id), newData);
          });
      
          console.log('Transformation completed successfully');
        } catch (error) {
          console.error('Error transforming documents:', error);
        }
      }

    const fetchUnits = async () => {
        try {
            const unitDocs = await getDocs(units);
            const unitsArray = unitDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setUnitsData(unitsArray);
        } catch (error) {
            console.error('Error fetching units data:', error);
        }
    };

    const fetchCenters = async (organizationDetailsName = null) => {
        try {
            let centersQuery = centers; // By default, fetch all centers

            if (organizationDetailsName) {
                // If organizationDetailsName is provided, find the organization ID
                const organizationSnapshot = await getDocs(
                    fireQuery(organizations, where('detailsName', '==', organizationDetailsName))
                );

                if (!organizationSnapshot.empty) {
                    const organizationId = organizationSnapshot.docs[0].id;

                    // Update the selected organization ID state
                    setSelectedOrganizationId(organizationId);

                    // Filter centers based on the organization ID
                    centersQuery = fireQuery(centers, where('organizationID', '==', organizationId));
                } else {
                    console.error('Organization document not found.');
                    return;
                }
            }

            const centersDocs = await getDocs(centersQuery);
            const centersArray = centersDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setCentersData(centersArray);
        } catch (error) {
            console.error('Error fetching centers data:', error);
        }
    };

    const fetchUnitIds = async (centerDetailsName) => {
        try {
            let unitsQuery = units; // By default, fetch all units

            if (centerDetailsName) {
                // If centerDetailsName is provided, find the center ID
                const centerSnapshot = await getDocs(
                    fireQuery(centers, where('detailsName', '==', centerDetailsName))
                );

                if (!centerSnapshot.empty) {
                    const centerId = centerSnapshot.docs[0].id;

                    // Update the selected organization ID state
                    setSelectedCenterId(centerId);

                    // Filter centers based on the organization ID
                    unitsQuery = fireQuery(units, where('centerID', '==', centerId));
                } else {
                    console.error('unit document not found.');
                    return;
                }
            }

            const unitsDocs = await getDocs(unitsQuery);
            const unitsArray = unitsDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setUnitsData(unitsArray);
        } catch (error) {
            console.error('Error fetching units data:', error);
        }
    };

    const fetchOrganizations = async () => {
        try {
            const organizationsDocs = await getDocs(organizations);
            const organizationsArray = organizationsDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setOrganizationsData(organizationsArray);
        } catch (error) {
            console.error('Error fetching organizations data:', error);
        }
    };

    const fetchIssueTypes = async (issueType) => {
        try {
            // If a specific issue type is selected, filter issue types based on the selected type
            const issueTypeQuery = issueType
                ? fireQuery(issue_types, where('type', '==', issueType.toLowerCase()))
                : issue_types;

            const issueTypesDocs = await getDocs(issueTypeQuery);
            const issueTypesArray = issueTypesDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setIssueTypesData(issueTypesArray);
        } catch (error) {
            console.error('Error fetching issue types data:', error);
        }
    };

    const openFormModal = () => {
        setShowFormModal(true);
    };

    const closeFormModal = () => {
        setShowFormModal(false);
    };

    const getIdFromDevices = (unitId, devType, unitsData) => {

        if (devType === 'Unit' || devType === 'Backend') {
            return unitId;
        }

        const unit = unitsData.find((unit) => unit.id === unitId);

        if (unit) {
            if (devType === 'Cartridge') {
                return unit.devices.kPodID;
            }
            else if (devType === 'Screen') {
                return unit.devices.displayID;
            }
        } else {
            // Handle the case when no matching document is found
            return null;
        }
    };

    const handleFormFieldChange = async (e) => {
        const { name, value, type, checked, options } = e.target;

        // Destructure the field name to access the nested properties
        const [mainKey, subKey] = name.split('.');

        if (subKey === 'message') {
            // Handle Issue.message as a single string
            setNewIssue((prevIssue) => ({
                ...prevIssue,
                [mainKey]: {
                    ...prevIssue[mainKey],
                    [subKey]: [value],
                },
            }));
        } else if (mainKey === 'Notification' && subKey === 'to' && options) {
            // Handle multiple select (Notification.to)
            const selectedOptions = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);

            setNewIssue((prevIssue) => ({
                ...prevIssue,
                [mainKey]: {
                    ...prevIssue[mainKey],
                    [subKey]: selectedOptions,
                },
            }));
        } else {
            if (mainKey === 'Installation' && subKey === 'Unit_ID') {
                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Installation: {
                        ...prevIssue.Installation,
                        Unit_ID: value,
                    },
                }));
            } else if (mainKey === 'Installation' && subKey === 'Center') {

                // Fetch centers based on the selected organization
                fetchUnitIds(value);

                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Installation: {
                        ...prevIssue.Installation,
                        Center: value,
                    },
                }));
            } else if (mainKey === 'Installation' && subKey === 'Organization') {
                // When the organization changes, update the selectedOrganization state
                setSelectedOrganization(value);

                // Fetch centers based on the selected organization
                fetchCenters(value);

                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Installation: {
                        ...prevIssue.Installation,
                        Organization: value,
                    },
                }));

            } else if (mainKey === 'Issue' && subKey === 'type') {
                // When the issue type changes, update the issue types based on the selected type
                setSelectedIssueType(value);

                fetchIssueTypes(value);

                // Reset the Issue.number field to maintain consistency
                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Issue: {
                        ...prevIssue.Issue,
                        type: value,
                    },
                }));
            } else if (mainKey === 'Issue' && subKey === 'number') {
                // Find the selected issue type based on the value
                const selectedIssueType = issueTypesData.find(
                    (issueType) => issueType.id === value
                );

                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Issue: {
                        ...prevIssue.Issue,
                        number: selectedIssueType ? selectedIssueType.number.toString() : '',
                    },
                }));
                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    Issue: {
                        ...prevIssue.Issue,
                        definition: selectedIssueType ? selectedIssueType.message : '',
                    },
                }));

            } else {

                // Handle other input types
                setNewIssue((prevIssue) => ({
                    ...prevIssue,
                    [mainKey]: {
                        ...prevIssue[mainKey],
                        [subKey]: type === 'checkbox' ? checked : value,
                    },
                }));
            }

        }
    };

    const handleMultiSelectChange = (selectedOptions, actionMeta) => {
        // Determine the field name based on actionMeta
        const name = actionMeta.name;

        // Determine the selected values
        const selectedValues = selectedOptions.map(option => option.value);

        // Destructure the field name to access the nested properties
        const [mainKey, subKey] = name.split('.');

        setNewIssue((prevIssue) => ({
            ...prevIssue,
            [mainKey]: {
                ...prevIssue[mainKey],
                [subKey]: selectedValues,
                Status: selectedValues.length > 0 ? 'notified' : 'unnotified',
            },
        }));
    };

    const postToSlack = async (message) => {
        try {
            const firebaseFunctionUrl = 'https://us-central1-kamleon-preproduction.cloudfunctions.net/postToSlack';

            // Make a POST request to the Firebase Function
            await axios.post(firebaseFunctionUrl, { message });

            console.log('Message sent to Slack successfully');
        } catch (error) {
            console.error('Error sending message to Slack:', error);
        }
    };

    const generateAndPostSlackMessage = async (issue) => {
        const assignedId = issue.Assigned.to;
        const notificationIds = issue.Notification.to;

        const mentionedUsers = [
            { name: 'Laia', lastname: 'Alentorn', slackId: 'UND5T3Y21'},
            { name: 'Gonzalo', lastname: 'Roca', slackId: 'UJRCTDWCA' },
            { name: 'Daniel', lastname: 'Vea', slackId: 'U01D92LPAJ1' },
            { name: 'Daniel', lastname: 'de Vargas', slackId: 'U061R65FXFE' },
            { name: 'Arnau', lastname: 'Crespo', slackId: 'U05VB1QBEP9' },
        ];

        // Function to mention users
        const mentionUsers = (users) => {
            return users.map((user) => `<@${user.slackId}>`).join(' ');
        };

        const getUserInfo = async (userId) => {
            return usersData.find((user) => user.id === userId);
        };

        const generateNotificationString = async (userIds) => {
            const usersInfo = await Promise.all(userIds.map((userId) => getUserInfo(userId)));

            if (usersInfo.length === 1) {
                const user = usersInfo[0];
                const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
            } else if (usersInfo.length === 2) {
                const mentions = usersInfo.map((user) => {
                    const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                    return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
                });
                return mentions.join(' and ');
            } else {
                const lastUser = usersInfo.pop();
                const usersString = usersInfo.map((user) => {
                    const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                    return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
                }).join(', ');
                const lastMention = mentionedUsers.find((mentionedUser) => mentionedUser.name === lastUser.name && mentionedUser.lastname === lastUser.lastName);
                const lastUserString = lastMention ? mentionUsers([lastMention]) : `${lastUser.name} ${lastUser.lastName}`;
                return `${usersString}, and ${lastUserString}`;
            }
        };

        const assignedUser = await getUserInfo(assignedId);
        var message = '';
        if (notificationIds.length === 0 && assignedId !== '') {
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mentionUsers(mentionedUsers.filter(user => user.name === assignedUser.name && user.lastname === assignedUser.lastName))}.`;
        }
        if (assignedId === '' && notificationIds.length > 0) {
            const notificationString = await generateNotificationString(notificationIds);
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID} and concerns ${notificationString}.`;
        }
        if (assignedId !== '' && notificationIds.length > 0) {
            const notificationString = await generateNotificationString(notificationIds);
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mentionUsers(mentionedUsers.filter(user => user.name === assignedUser.name && user.lastname === assignedUser.lastName))} and concerns ${notificationString}.`;
        }
        if (assignedId === '' && notificationIds.length === 0) {
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}.`;
        }

        postToSlack(message);
    }

    const createIssueFromForm = async () => {
        if (validateForm()) {
            try {
                const unitId = newIssue.Installation.Unit_ID[0];
                const deviceId = await getIdFromDevices(unitId, newIssue.Installation.Dev_type, unitsData);

                // const kamEyeIssuesSnapshot = await getDocs(kam_eyeIssues);
                const issueNumber = newIssue.Issue.number;
                const nextIssueId = `M${newIssue.Installation.Dev_type[0]}${newIssue.Issue.type[0].toLowerCase()}${issueNumber}`;

                const newIssueData = {
                    Assigned: {
                        to: newIssue.Assigned.to,
                        Status: newIssue.Assigned.to !== '' ? 'assigned' : 'unassigned',
                    },
                    Installation: {
                        Dev_type: newIssue.Installation.Dev_type,
                        ID: deviceId,
                        Unit_ID: newIssue.Installation.Unit_ID,
                        Organization: newIssue.Installation.Organization,
                        Center: newIssue.Installation.Center,
                    },
                    Notification: {
                        to: newIssue.Notification.to,
                        Automatic: newIssue.Notification.Automatic,
                        Status: newIssue.Notification.Automatic === true ? 'notified' : newIssue.Notification.Status,
                    },
                    timestamps: {
                        open: {
                            Created_at: serverTimestamp(),
                            Last_open: serverTimestamp(),
                        },
                    },
                    Issue: {
                        ID: nextIssueId,
                        type: newIssue.Issue.type,
                        status: newIssue.Issue.status,
                        number: newIssue.Issue.number,
                        definition: newIssue.Issue.definition,
                        message: newIssue.Issue.message,
                    }
                };
                // Add the issue to Realtime Database under /kam_eye
                const kamEyeRef = ref(database, `/kam_eye/${nextIssueId}`);
                await set(kamEyeRef, {
                    ...newIssueData,
                    timestamps: {
                    open: {
                        Created_at: Date.now() / 1000,  // Convert to Unix timestamp
                        Last_open: Date.now() / 1000,
                    },
                    }
                });

                // Reset the form fields and hide the form
                setNewIssue({
                    Assigned: {
                        to: '',
                    },
                    Installation: {
                        Dev_type: '',
                        ID: '',
                        Unit_ID: '',
                        Organization: '',
                        Center: '',
                    },
                    Notification: {
                        to: [""],
                        Automatic: false,
                        Status: 'unnotified',
                    },
                    Issue: {
                        ID: '',
                        type: '',
                        status: 'Open',
                        number: '',
                        definition: '',
                        message: [""],
                    },
                });
                setShowFormModal(false);
            } catch (error) {
                console.error('Error emulating issue:', error);
            }
        }
    };

    useEffect(() => {
        fetchUnits();
        fetchCenters();
        fetchOrganizations();
        fetchIssueTypes();
    }, []);

    return (
        <div>
            <button type="button" className="btn btn-primary fixed-button d-flex align-items-center" onClick={() => openFormModal()}><span className="custom-size-span me-1 mb-1">+</span> <strong>ADD NEW ISSUE</strong></button>
            {showFormModal && (
                <Modal dialogClassName="modal-lg" show={showFormModal} onHide={closeFormModal}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className='container'>
                                <div className='row pb-2'>
                                    <div className='col-6'>
                                        <h6><strong>ORGANIZATION</strong></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><strong>STATUS</strong></h6>
                                    </div>
                                </div>
                                <div className='row pb-2'>
                                    <div className='col-6'>
                                        <select
                                            className={`form-select ${
                                            validationErrors['Installation.Organization'] ? 'is-invalid' : ''
                                                }`}
                                            name="Installation.Organization"
                                            defaultValue={newIssue.Installation.Organization}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Select organization</option>
                                            {organizationsData.map((org) => (
                                                <option key={org.detailsName} value={org.detailsName}>
                                                    {org.detailsName}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {validationErrors['Installation.Organization']}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <select
                                            className="form-select"
                                            name="Issue.status"
                                            defaultValue={newIssue.Issue.status}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="Open">Open</option>
                                            <option value="In progress">In progress</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row py-2'>
                                    <div className='col-6'>
                                        <h6><strong>CENTER</strong></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><strong>ASSIGNED</strong></h6>
                                    </div>
                                </div>
                                <div className='row pb-2'>
                                    <div className='col-6'>
                                        <select
                                            className={`form-select ${
                                                validationErrors['Installation.Center'] ? 'is-invalid' : ''
                                            }`}
                                            name="Installation.Center"
                                            defaultValue={newIssue.Installation.Center}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Select center</option>
                                            {centersData.map((center) => (
                                                <option key={center.detailsName} value={center.detailsName}>
                                                    {center.detailsName}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {validationErrors['Installation.Center']}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <select
                                            className={`form-select ${
                                                validationErrors['Assigned.to'] ? 'is-invalid' : ''
                                            }`}
                                            name="Assigned.to"
                                            defaultValue={newIssue.Assigned.to}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Select person</option>
                                            {usersData.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.name} {user.lastName}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {validationErrors['Assigned.to']}
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-2'>
                                    <div className='col-6'>
                                        <h6><strong>UNIT ID</strong></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><strong>NOTIFICATION TO</strong></h6>
                                    </div>
                                </div>
                                <div className='row pb-2'>
                                    <div className='col-6'>
                                        <select
                                            className={`form-select ${
                                                validationErrors['Installation.Unit_ID'] ? 'is-invalid' : ''
                                            }`}
                                            name="Installation.Unit_ID"
                                            defaultValue={newIssue.Installation.Unit_ID}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Select unit ID</option>
                                            {unitsData.map((unit) => (
                                                <option key={unit.id} value={unit.id}>
                                                    {unit.id}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {validationErrors['Installation.Unit_ID']}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <Select
                                            defaultValue={usersData.filter(user => newIssue.Notification.to.includes(user.id))}
                                            isMulti
                                            name="Notification.to"
                                            options={usersData.map((user) => ({
                                                value: user.id,
                                                label: `${user.name} ${user.lastName}`
                                            }))}
                                            onChange={handleMultiSelectChange}
                                            className="basic-multi-select clear-background"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                                <div className='row py-2'>
                                    <div className='col-6'>
                                        <h6><strong>DEVICE</strong></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><strong>MESSAGE</strong></h6>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <select
                                            className={`form-select ${validationErrors['Installation.Dev_type'] ? 'is-invalid' : ''}`}
                                            name="Installation.Dev_type"
                                            defaultValue={newIssue.Installation.Dev_type}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Type</option>
                                            <option value="Backend">Backend</option>
                                            <option value="Cartridge">Cartridge</option>
                                            <option value="Screen">Screen</option>
                                            <option value="Unit">Unit</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Type is required
                                        </div>
                                        <div className='col-6 pt-3 pb-2'>
                                            <h6><strong>ISSUE TYPE</strong></h6>
                                        </div>
                                        <select
                                            className={`form-select ${validationErrors['Issue.type'] ? 'is-invalid' : ''}`}
                                            name="Issue.type"
                                            defaultValue={newIssue.Issue.type}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Type</option>
                                            <option value="Critical">Critical</option>
                                            <option value="Error">Error</option>
                                            <option value="Warning">Warning</option>
                                            <option value="Exception">Exception</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Type is required
                                        </div>
                                        <div className='col-6 pt-3 pb-2'>
                                            <h6><strong>ISSUE</strong></h6>
                                        </div>
                                        <select
                                            className={`form-select ${
                                          validationErrors['Issue.number'] ? 'is-invalid' : ''
                                                }`}
                                            name="Issue.number"
                                            defaultValue={newIssue.Issue.number}
                                            onChange={handleFormFieldChange}
                                        >
                                            <option value="">Select issue</option>
                                            {issueTypesData
                                                .sort((a, b) => a.id - b.id)
                                                .map((issueType) => (
                                                    <option key={issueType.id} value={issueType.id}>
                                                        {issueType.id}. {issueType.message}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            {validationErrors['Issue.number']}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <textarea
                                            rows="3"
                                            className={`form-control dark-placeholder-color ${validationErrors['Issue.message'] ? 'is-invalid' : ''}`}
                                            type="text"
                                            name="Issue.message"
                                            placeholder="Message"
                                            defaultValue={newIssue.Issue.message}
                                            onChange={handleFormFieldChange}
                                        ></textarea>
                                        <div className="invalid-feedback">
                                            Message is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center align-items-center flex-column">
                        <Button variant="primary my-2" onClick={createIssueFromForm}><strong>CREATE ISSUE</strong></Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};