import React from 'react';
import { Modal, Spinner, ProgressBar } from 'react-bootstrap';

const ClosingProgressPopup = ({ show, currentIssue, totalIssues, action }) => {
  return (
    <Modal
      show={show}
      onHide={() => {}}
      backdrop="static"
      keyboard={false}
      style={{ top: '150px' }}
    >
      <Modal.Header>
        <Modal.Title>{action.charAt(0).toUpperCase() + action.slice(1)} Issues ...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
        <div className="text-center">
          Closing issue {currentIssue} of {totalIssues}
        </div>
        {/* <ProgressBar
          now={(currentIssue / totalIssues) * 100}
          label={`${Math.round((currentIssue / totalIssues) * 100)}%`}
          className="mt-3"
        /> */}
      </Modal.Body>
    </Modal>
  );
};

export default ClosingProgressPopup;