import { useState, useEffect } from 'react';
import { db, db2, db3 } from '../firebase';
import {
    getDocs,
    doc,
    getDoc,
    setDoc,
    collection,
    query as fireQuery,
    where,
} from 'firebase/firestore';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';

export const IssueSettings = ({ usersData, onShow, onClose }) => {
    const issue_types = collection(db3, 'monitoring_issue_types');
    const [issueTypesData, setIssueTypesData] = useState([]);
    const [showNewIssueTypeForm, setShowNewIssueTypeForm] = useState(false);
    const [showRepeatedIdMessage, setShowRepeatedIdMessage] = useState(false);

    const [issueType, setIssueType] = useState(null);
    const [editedIssueType, seteditedIssueType] = useState({
        Actions: '',
        Cause: '',
        assigned: '',
        assignees: [],
        automatic_notification: true,
        comments: '',
        message: '',
        notification_channel: {
            ntfy: false,
            slack: true,
        },
        number: '',
        origin: '',
        type: '',
    });

    
    const [validationErrors, setValidationErrors] = useState({
        'origin': '',
        'type': '',
        'number': '',
        'message': '',
    });

    const validateForm = () => {
        const errors = {
            'origin': '',
            'type': '',
            'number': '',
            'message': '',
        };
        if (!editedIssueType.origin) {
            errors['origin'] = 'Origin is required';
        }
        if (!editedIssueType.type) {
            errors['type'] = 'Type is required';
        }
        if (!editedIssueType.number) {
            errors['number'] = 'Number is required';
        }
        if (!editedIssueType.message) {
            errors['message'] = 'Message is required';
        }

        setValidationErrors(errors);

        // Check if there are any errors
        return Object.values(errors).every((error) => !error);
    };


    const fetchIssueTypes = async (issueType) => {
        try {
            // If a specific issue type is selected, filter issue types based on the selected type
            const issueTypeQuery = issueType
                ? fireQuery(issue_types, where('type', '==', issueType.toLowerCase()))
                : issue_types;

            const issueTypesDocs = await getDocs(issueTypeQuery);
            const issueTypesArray = issueTypesDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setIssueTypesData(issueTypesArray);
        } catch (error) {
            console.error('Error fetching issue types data:', error);
        }
    };

    const updateAssignedTo = (userId) => {
        if (issueType) {
            setIssueType({
                ...issueType,
                assigned: userId,
            });
        }
    };

    const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    // Determine the field name based on actionMeta
        const name = actionMeta.name;

        // Determine the selected values
        const selectedValues = selectedOptions.map((option) => option.value);

        // Destructure the field name to access the nested properties
        const [mainKey, subKey] = name.split('.');

        setIssueType((prevIssueType) => ({
            ...prevIssueType,
            [mainKey]: selectedValues,
        }));
    };

    const handleEditedMultiSelectChange = (selectedOptions, actionMeta) => {
    // Determine the field name based on actionMeta
        const name = actionMeta.name;

        // Determine the selected values
        const selectedValues = selectedOptions.map((option) => option.value);

        // Destructure the field name to access the nested properties
        const [mainKey, subKey] = name.split('.');

        seteditedIssueType((prevIssueType) => ({
            ...prevIssueType,
            [mainKey]: selectedValues,
        }));
    };

    const saveNewIssue = async () => {
        setShowRepeatedIdMessage(false);
        if (validateForm()) {
            try {
                 // Extract information needed for custom ID
                const idPrefix =
                    editedIssueType.origin.charAt(0).toUpperCase() +
                    (editedIssueType.type === 'exception' ? 'Ex' : editedIssueType.type.charAt(0).toUpperCase());
                const customId = idPrefix + editedIssueType.number;

                // Check if a document with the same ID already exists
                const existingDoc = await getDoc(doc(collection(db3, 'monitoring_issue_types'), customId));

                if (existingDoc.exists()) {
                    setShowRepeatedIdMessage(true);
                    console.error('A document with the same ID already exists.');
                    return;
                }

                // Add your logic to validate the required fields
                if (!editedIssueType.origin || !editedIssueType.type) {
                    // Display an error message or handle validation
                    console.error('Origin and Type are required fields.');
                    return;
                }

                const newIssueData = {
                    ...editedIssueType,
                };

                const newIssueRef = await setDoc(doc(collection(db3, 'monitoring_issue_types'), customId), newIssueData);

                console.log('New issue created successfully');

                // Clear the form 
                seteditedIssueType({
                    Actions: '',
                    Cause: '',
                    assigned: '',
                    assignees: [],
                    automatic_notification: true,
                    comments: '',
                    message: '',
                    notification_channel: {
                        ntfy: false,
                        slack: true,
                    },
                    number: '',
                    origin: '',
                    type: '',
                });
        
                // Close the modal
                setShowNewIssueTypeForm(false);
                onClose();
            } catch (error) {
                console.error('Error creating a new issue:', error);
            }
        }
    };

    const saveEditedIssue = async () => {
        if (issueType) {
            try {
                const issueRef = doc(db3, 'monitoring_issue_types', issueType.id);

                if (!issueType.assignees.includes(issueType.assigned)) {
                    setIssueType((prevIssue) => ({
                        ...prevIssue,
                        assignees: Array.from(new Set([...prevIssue.assignees, issueType.assigned])),
                    }));
                }
    
                const updatedIssueData = {
                    ...issueType,
                    Actions: issueType.Actions,
                    Cause: issueType.Cause,
                    assigned: issueType.assigned,
                    assignees: issueType.assignees,
                    automatic_notification: issueType.automatic_notification,
                    comments: issueType.comments,
                    message: issueType.message,
                    notification_channel: {
                        ntfy: issueType.notification_channel.ntfy,
                        slack: issueType.notification_channel.slack,
                    },
                    number: issueType.number,
                    origin: issueType.origin,
                    type: issueType.type,
                    //ID: issueType.ID, // Assuming ID is a direct property of currentIssue
                };
    
                await setDoc(issueRef, updatedIssueData);
    
                setIssueType(null);
            } catch (error) {
                console.error('Error updating the issue:', error);
            }
        }
        onClose();
        setIssueType(null);
    };

    const handleIssueType = async (issueType) => {
        if (issueType === " ") {
            setIssueType(null);
        } else {
            setIssueType(issueType);
        }
    }

    useEffect(() => {
        fetchIssueTypes();
    }, []);

    return (
        <div>
            <Modal dialogClassName="modal-lg" show={onShow} onHide={onClose}>
                <Modal.Header closeButton>
                        <div className="d-flex justify-content-center align-items-end w-100 pt-1">
                            <Modal.Title>
                                <h5>
                                    <strong>
                                            ISSUE SETTINGS
                                    </strong>
                                </h5>
                            </Modal.Title>
                        </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className='container'>
                            {!showNewIssueTypeForm && (
                                <div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h6><strong>SELECT ISSUE TYPE</strong></h6>
                                        </div>
                                    </div>
                                    <div className='row pb-4'>
                                        <div className='col-9 pt-2 pb-2'>
                                            <select
                                                className="form-select"
                                                name="Issue.number"
                                                defaultValue={editedIssueType.id}
                                                onChange={(e) => handleIssueType(e.target.value !== " " ? JSON.parse(e.target.value) : e.target.value)}
                                            >
                                                <option value=" ">Select issue</option>
                                                {issueTypesData
                                                    .sort((a, b) => a.id - b.id)
                                                    .map((issueType) => (
                                                        <option key={issueType.id} value={JSON.stringify(issueType)}>
                                                            {issueType.id}. {issueType.message}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {!issueType && (
                                        <div className='row'>
                                                <div className='col-1 pt-1'><h6><strong>OR </strong></h6></div>
                                                <div className='col-6 ps-0'>
                                                <h6 className='text-start'>
                                                    <strong>
                                                        <a
                                                            href="#"
                                                            className="btn btn-link text-danger blue-link text-start"
                                                            onClick={() => {
                                                                setShowNewIssueTypeForm(true);
                                                            }}
                                                        >
                                                            CREATE A NEW ONE
                                                        </a>
                                                    </strong>
                                                </h6>
                                                </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {showNewIssueTypeForm && (
                                <div className='row pb-2'>
                                    <div className='col-6 pt-2'>
                                        <div className='pb-2'>
                                            <h6><strong>ORIGIN</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <select
                                                className={`form-select ${
                                                    validationErrors['origin'] ? 'is-invalid' : ''
                                                }`}
                                                name="origin"
                                                defaultValue={editedIssueType.origin}
                                                onChange={(e) => {
                                                    seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    origin: e.target.value,
                                                    }));
                                                }}
                                            >
                                                <option value="">Select origin</option>
                                                <option value="backend">Backend</option>
                                                <option value="cartridge">Cartridge</option>
                                                <option value="screen">Screen</option>
                                                <option value="unit">Unit</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                {validationErrors['origin']}
                                            </div>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>TYPE</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <select
                                                className={`form-select ${
                                                    validationErrors['type'] ? 'is-invalid' : ''
                                                }`}
                                                name="type"
                                                defaultValue={editedIssueType.type}
                                                onChange={(e) => {
                                                    seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    type: e.target.value,
                                                    }));
                                                }}
                                            >
                                                <option value="">Select type</option>
                                                <option value="critical">Critical</option>
                                                <option value="error">Error</option>
                                                <option value="warning">Warning</option>
                                                <option value="exception">Exception</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                {validationErrors['type']}
                                            </div>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>NUMBER</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <textarea
                                            rows="1"
                                            className={`form-control dark-placeholder-color ${
                                                validationErrors['number'] ? 'is-invalid' : ''
                                            }`}
                                            name="Number"
                                            placeholder="Choose number"
                                            value={editedIssueType.number}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value);
                                                seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    number: isNaN(value) ? '' : value,
                                                }));
                                            }}
                                            ></textarea>
                                            <div className="invalid-feedback">
                                                {validationErrors['number']}
                                            </div>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>MESSAGE</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <textarea
                                            rows="2"
                                            className={`form-control dark-placeholder-color ${
                                                validationErrors['message'] ? 'is-invalid' : ''
                                            }`}
                                            name="Message"
                                            placeholder="Message"
                                            value={editedIssueType.message}
                                            onChange={(e) => {
                                                seteditedIssueType((prevIssueType) => ({
                                                ...prevIssueType,
                                                message: e.target.value,
                                                }));
                                            }}
                                            ></textarea>
                                            <div className="invalid-feedback">
                                                {validationErrors['message']}
                                            </div>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>CAUSE</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <textarea
                                            rows="3"
                                            className="form-control dark-placeholder-color"
                                            name="Cause"
                                            placeholder="Cause"
                                            value={editedIssueType.Cause}
                                            onChange={(e) => {
                                                const actionsArray = e.target.value.split('\n').map((cause) => cause.trim());
                                                seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    Cause: actionsArray,
                                                }));
                                            }}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className='col-6 pt-2'>
                                        <div className='pb-2'>
                                            <h6><strong>ACTIONS</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <textarea
                                            rows="3"
                                            className="form-control dark-placeholder-color"
                                            name="Actions"
                                            placeholder="Actions"
                                            value={editedIssueType.Actions}
                                            onChange={(e) => {
                                                const actionsArray = e.target.value.split('\n').map((action) => action.trim());
                                                seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    Actions: actionsArray,
                                                }));
                                            }}
                                            ></textarea>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>ASSIGNED</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <select
                                                className="form-select"
                                                name="Assigned"
                                                defaultValue={editedIssueType.assigned}
                                                onChange={(e) => {
                                                    seteditedIssueType((prevIssueType) => ({
                                                    ...prevIssueType,
                                                    Actions: e.target.value,
                                                    }));
                                                }}
                                            >
                                                <option value="">Select person</option>
                                                {usersData.map((user) => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name} {user.lastName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>NOTIFICATION TO</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <Select
                                                defaultValue={usersData
                                                    .filter(user => editedIssueType.assignees.includes(user.id))
                                                    .map(user => ({
                                                        key: user.id,
                                                        value: user.id,
                                                        label: `${user.name} ${user.lastName}`
                                                    }))
                                                }

                                                isMulti
                                                name="assignees"
                                                options={usersData.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.name} ${user.lastName}`
                                                }))}
                                                onChange={handleEditedMultiSelectChange}
                                                className="basic-multi-select clear-background"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>COMMENTS</strong></h6>
                                        </div>
                                        <div className='pb-2'>
                                            <textarea
                                                rows="3"
                                                className="form-control dark-placeholder-color"
                                                type="text"
                                                name="comments"
                                                placeholder="Comments"
                                                defaultValue={editedIssueType.comments}
                                                onChange={(e) => {
                                                    seteditedIssueType((prevIssue) => ({
                                                        ...prevIssue,
                                                        comments: e.target.value,
                                                    }));
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {issueType && (
                                <div className='row pb-2'>
                                    <div className='col-6'>
                                        <table className="table inherit-color">
                                            <tbody>
                                                <tr>
                                                    <td><strong className="text-muted">ORIGIN</strong></td>
                                                    <td className="text-end text-muted">{issueType.origin}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong className="text-muted">TYPE</strong></td>
                                                    <td className="text-end text-muted">{issueType.type}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong className="text-muted">CAUSE</strong></td>
                                                    <td className="text-end text-muted">
                                                        {issueType.Cause && issueType.Cause.length > 0 ? (
                                                        issueType.Cause.map((cause, index) => (
                                                            <div key={index}>{cause}</div>
                                                        ))
                                                        ) : (
                                                        <div></div>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong className="text-muted">ACTIONS</strong></td>
                                                    <td className="text-end text-muted">
                                                        {issueType.Actions && issueType.Actions.length > 0 ? (
                                                        issueType.Actions.map((action, index) => (
                                                            <div key={index}>{action}</div>
                                                        ))
                                                        ) : (
                                                        <div></div>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong className="text-muted">MESSAGE</strong></td>
                                                    <td className="text-end text-muted">{issueType.message}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-6 pt-2'>
                                        <div className='pb-2'>
                                            <h6><strong>ASSIGNED</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <select
                                                className="form-select"
                                                name="Assigned.to"
                                                value={issueType.assigned}
                                                onChange={(e) => updateAssignedTo(e.target.value)}
                                            >
                                                <option value="">Select person</option>
                                                {usersData.map((user) => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name} {user.lastName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>NOTIFICATION TO</strong></h6>
                                        </div>
                                        <div className='pb-3'>
                                            <Select
                                                value={usersData
                                                    .filter(user => issueType.assignees.includes(user.id))
                                                    .map(user => ({
                                                        key: user.id,
                                                        value: user.id,
                                                        label: `${user.name} ${user.lastName}`
                                                    }))
                                                }

                                                isMulti
                                                name="assignees"
                                                options={usersData.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.name} ${user.lastName}`
                                                }))}
                                                onChange={handleMultiSelectChange}
                                                className="basic-multi-select clear-background"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className='pb-2'>
                                            <h6><strong>COMMENTS</strong></h6>
                                        </div>
                                        <div className='pb-2'>
                                            <textarea
                                                rows="3"
                                                className="form-control dark-placeholder-color"
                                                type="text"
                                                name="Issue.comments"
                                                placeholder="Comments..."
                                                value={issueType.comments}
                                                onChange={(e) => {
                                                    setIssueType((prevIssue) => ({
                                                        ...prevIssue,
                                                        comments: e.target.value,
                                                    }));
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                        variant="primary my-2"
                        onClick={
                            showNewIssueTypeForm ? saveNewIssue : saveEditedIssue
                        }
                    >
                        <strong>SAVE ISSUE</strong>
                    </Button>
                    <div className="d-flex justify-content-center align-items-center">
                        {showRepeatedIdMessage && (
                            <div className="text-danger">
                                <strong>
                                    An issue type with the same origin, type andd number already exists. Please choose a different number.
                                </strong>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};