// IssuesTable.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../checkbox.css';
import { Checkbox } from 'semantic-ui-react';
import { NewIssue } from './newIssue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ArrowLeft } from '../img/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../img/arrow_right.svg';
import { ReactComponent as Ghost } from '../img/ghost.svg';
import { ReactComponent as Critical } from '../img/critical.svg';
import { ReactComponent as Error } from '../img/error.svg';
import { ReactComponent as Warning } from '../img/warning.svg';
import { ReactComponent as Exception } from '../img/exception.svg';

export const IssuesTable = ({
  currentIssuesList,
  groupIssues,
  handleSortIssue,
  handleHandleIssue,
  handleMultiSelectChange,
  handleHideIssuesMultiSelectChange,
  setGroupIssues,
  issueStatus,
  issueIDs,
  customStyles,
  issueStatusSelected,
  issueTypeFilter,
  setIssueTypeFilter,
  issueIdFilter,
  setIssueIdFilter,
  formatTimestamp,
  classifiedIssuesbyUnit,
  selectedUnit,
  selectedUnitCenter,
  selectedUnitDescription,
  classifiedIssues,
  usersData,
  userName,
  userLastName,
  showHandleIssue,
  setShowHandleIssue,
  issueToModify,
  issueGroupToModify,
  backToDashboard,
  setShowMqttModal,
  classifiedIssuesbyUnitAndKey,
  userRole,
}) => {
  return (
    <div className="mt-4">
              <div className="text-start mb-3">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-link ps-0 pe-2 mb-3"
                    onClick={() => { backToDashboard() }}
                  >
                    <ArrowLeft style={{ width: '19px', height: '16px' }} />
                  </button>
                  <h5><strong>Unit {selectedUnit} Issues | {selectedUnitCenter} | {selectedUnitDescription}</strong></h5>
                </div>
              </div>
              <div className='row g-3 pb-4'>
                <div className="col-3 me-2 ms-0 text-start">
                  <Select
                    defaultValue={issueStatusSelected}
                    isMulti
                    name="issueStatusSelected"
                    placeholder="Select issue states"
                    onChange={handleMultiSelectChange}
                    options={issueStatus.map((status) => ({
                      value: status.value,
                      label: status.label
                    }))}
                    className="basic-multi-select clear-background"
                    classNamePrefix="select"
                  />
                </div>
                <div className="col-2 ms-0">
                  <select
                    className='form-select'
                    value={issueTypeFilter}
                    onChange={(e) => {
                      setIssueTypeFilter(e.target.value);
                    }}
                  >
                    <option value="">All Issue Types</option>
                    <option value="Critical">Critical</option>
                    <option value="Error">Error</option>
                    <option value="Warning">Warning</option>
                    <option value="Exception">Exception</option>
                  </select>
                </div>
                <div className="col-3 ps-0 text-start">
                  <div className="row ms-2">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="issueIDs"
                      placeholder="Select issues to hide"
                      onChange={handleHideIssuesMultiSelectChange}
                      options={[
                        ...issueIDs.map((id, index) => ({ value: id, label: id })),
                      ]}
                      className="basic-multi-select clear-background"
                      classNamePrefix="select"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col-2 text-start pe-0">
                  <div className="row">
                    <div className="col-7 pe-0" style={{ maxWidth: '300px' }}>
                      <label className="mt-2">Group issues</label>
                    </div>
                    <div className="col-5 ps-0">
                      <Checkbox
                        className="mt-2"
                        toggle  // Use "toggle" for a switch-like appearance
                        checked={groupIssues}
                        onChange={() => {
                          setGroupIssues(!groupIssues);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col m-0 mt-3 form-search">
                  <div className="input-container ms-0">
                    <span className="icon"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: '#212529' }} /></span>
                    <input
                      className='form-control dark-placeholder-color'
                      type="text"
                      placeholder="Search by Nº"
                      style={{ borderRadius: '21px' }}
                      value={issueIdFilter}
                      onChange={(e) => {
                        setIssueIdFilter(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='pb-4'>
                {currentIssuesList.length > 0 ? (
                <table className="table table-striped table-hover bdr mb-5">
                  <thead className="table-dark rounded-top">
                    <tr>
                      <th onClick={() => handleSortIssue('Issue.type')}>
                        ISSUE TYPE
                        {/* <span className={`arrow ${sortField === 'Issue.type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSortIssue('Issue.ID')}>
                        ISSUE Nº
                        {/* <span className={`arrow ${sortField === 'Issue.ID' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSortIssue('timestamps.open.Created_at')}>
                        TIME
                        {/* <span className={`arrow ${sortField === 'timestamps.open.Created_at' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSortIssue('Issue.definition')}>
                        ISSUE
                        {/* <span className={`arrow ${sortField === 'Installation.Organization' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSortIssue('Installation.Dev_type')}>
                        DEVICE
                        {/* <span className={`arrow ${sortField === 'Installation.Dev_type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSortIssue('Issue.status')}>
                        STATUS
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(currentIssuesList).map((item) => {
                      const installationKey = `${item.Installation.Center}-${item.Installation.Dev_type}-${item.Installation.ID}-${item.Installation.Organization}-${item.Installation.Unit_ID}`;
                      const issueKey = `${item.Issue.ID}-${item.Issue.number}-${item.Issue.type}-${item.Issue.status}`;
                      const combinedKey = `${installationKey}-${issueKey}`;

                      const issueCount = groupIssues ? classifiedIssuesbyUnitAndKey[selectedUnit][combinedKey]?.length : 0;
                      return (
                        <tr
                          key={item.id}
                          onClick={() => handleHandleIssue(item, classifiedIssues[combinedKey])}
                          style={{ cursor: 'pointer' }}
                        >
                          {groupIssues ? (
                            <td className="">
                              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <span className='ms-0 me-0'>
                                  {item.Issue.type === 'Critical' && (
                                    <Critical style={{ width: '32px', height: '32px' }} />
                                  )}
                                  {item.Issue.type === 'Error' && (
                                    <Error style={{ width: '32px', height: '32px' }} />
                                  )}
                                  {item.Issue.type === 'Warning' && (
                                    <Warning style={{ width: '32px', height: '32px' }} />
                                  )}
                                  {item.Issue.type === 'Exception' && (
                                    <Exception style={{ width: '32px', height: '32px' }} />
                                  )}
                                  {item.Issue.type !== 'Critical' && item.Issue.type !== 'Error' && item.Issue.type !== 'Warning' && item.Issue.type !== 'Exception' && (
                                    item.Issue.type
                                  )}
                                </span>
                                <span className='ms-2' >
                                  {`x${issueCount}`}
                                </span>
                              </span>
                            </td>
                          ) : (
                            <td>
                              {item.Issue.type === 'Critical' && (
                                <Critical style={{ width: '32px', height: '32px' }} />
                              )}
                              {item.Issue.type === 'Error' && (
                                <Error style={{ width: '32px', height: '32px' }} />
                              )}
                              {item.Issue.type === 'Warning' && (
                                <Warning style={{ width: '32px', height: '32px' }} />
                              )}
                              {item.Issue.type === 'Exception' && (
                                <Exception style={{ width: '32px', height: '32px' }} />
                              )}
                              {item.Issue.type !== 'Critical' && item.Issue.type !== 'Error' && item.Issue.type !== 'Warning' && item.Issue.type !== 'Exception' && (
                                item.Issue.type
                              )}
                            </td>
                          )}
                          <td>{item.Issue.ID}</td>
                          <td>{formatTimestamp(item.timestamps.open.Created_at).split(' ')[0]} - {formatTimestamp(item.timestamps.open.Created_at).split(' ')[1]}</td>
                          <td style={{ textAlign: 'left' }}>{item.Issue.definition}</td>
                          <td>{item.Installation.Dev_type}</td>
                          <td className="text-start">
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <span
                                className='ms-3 me-3'
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                  marginRight: '5px',
                                  backgroundColor:
                                    item.Issue.status === 'Open'
                                      ? '#FC664E'
                                      : item.Issue.status === 'In progress'
                                        ? '#FFD952'
                                        : item.Issue.status === 'Closed'
                                          ? '#2FD5CB'
                                          : 'transparent',
                                }}
                              ></span>
                              {item.Issue.status}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                ) : (
                  <div>
                    <table className="table table-striped table-hover bdr mb-5">
                      <thead className="table-dark rounded-top">
                        <tr>
                          <th onClick={() => handleSortIssue('Issue.type')}>
                            ISSUE TYPE
                            {/* <span className={`arrow ${sortField === 'Issue.type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                          <th onClick={() => handleSortIssue('Issue.ID')}>
                            ISSUE Nº
                            {/* <span className={`arrow ${sortField === 'Issue.ID' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                          <th onClick={() => handleSortIssue('timestamps.open.Created_at')}>
                            TIME
                            {/* <span className={`arrow ${sortField === 'timestamps.open.Created_at' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                          <th onClick={() => handleSortIssue('Issue.definition')}>
                            ISSUE
                            {/* <span className={`arrow ${sortField === 'Installation.Organization' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                          <th onClick={() => handleSortIssue('Installation.Dev_type')}>
                            DEVICE
                            {/* <span className={`arrow ${sortField === 'Installation.Dev_type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                          <th onClick={() => handleSortIssue('Issue.status')}>
                            STATUS
                            {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div className="text-center mt-5">
                      <Ghost style={{ width: '500px'}}/>
                    </div>
                    <div className="text-center text-muted mt-4 mb-5">
                      <h5>Oh, it looks like you have no issues!</h5>
                    </div>
                  </div>
                )}
              </div>
              {selectedUnit !== 'Rhampholeon' && userRole !== "kamleon_viewer" && (
                <>
                  <button
                    type="button"
                    className="btn fixed-button-left ps-0 pe-2 mb-3"
                    onClick={() => { setShowMqttModal(true) }}
                  >
                    <h5 style={{color: '#25CFC4'}}>Take an action <ArrowRight className="mb-1" style={{ width: '19px', height: '16px' }} /> </h5>
                  </button>
                  <NewIssue usersData={usersData} />
                </>
              )}
            </div>
  );
};
