import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import MqttComponent from './mqttComponent';
import ClosingProgressPopup from "./closingProgressPopup";
import { ConfirmationActionPopup } from './confirmationActionPopup';
import { ReactComponent as ArrowLeft } from '../img/arrow_left.svg';
import { db, db2, db3, database } from '../firebase';
import { ref, onValue,  get, set, update, serverTimestamp } from 'firebase/database';
import {
    getDoc,
    setDoc,
    collection,
    doc,
} from 'firebase/firestore';
import Select from 'react-select';

export const HandleIssue = ({ issue, issueGroup, usersData, userName, userLastName, onShow, onClose, userRole, unitsData, devicesData }) => {
    var isMorethanOne = false;
    if (issueGroup != {} && issueGroup.length > 1) {
        isMorethanOne = true;
    }
    const kam_eye_issues = collection(db3, 'kam_eye');
    const kamEyePath = '/kam_eye';
    const kamEyeRef = ref(database, kamEyePath);
    const [editedIssue, setEditedIssue] = useState(null);
    const [isAssignedOrNotified, setIsAssignedOrNotified] = useState(false);
    const [showConfirmationActionPopup, setShowConfirmationActionPopup] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState(null);
    const [takeActionToAll, setTakeActionToAll] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [showProgressPopup, setShowProgressPopup] = useState(false);
    const [currentIssueIndex, setCurrentIssueIndex] = useState(0);
    function addLeadingZero(number) {
        return number < 10 ? '0' + number : number;
    }

    function formatTimestamp(timestamp) {
        if (!timestamp) {
            return '-';
        }

        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
    }

    const closeEditIssue = () => {
        onClose();
        setEditedIssue(null);
    }

    const postToSlack = async (message) => {
        try {
            const firebaseFunctionUrl = 'https://us-central1-kamleon-preproduction.cloudfunctions.net/postToSlack';

            // Make a POST request to the Firebase Function
            await axios.post(firebaseFunctionUrl, { message });

            console.log('Message sent to Slack successfully');
        } catch (error) {
            console.error('Error sending message to Slack:', error);
        }
    };

    const generateAndPostSlackMessage = async (issue) => {
        const assignedId = issue.Assigned.to;
        const notificationIds = issue.Notification.to;

        const mentionedUsers = [
            { name: 'Sergi', lastname: 'Consul', slackId: 'UJHUBF3JP' },
            { name: 'Adrià', lastname: 'Arnaste', slackId: 'U040NJ7RGET' },
            { name: 'Valentino', lastname: 'Asole', slackId: 'U04KEPM5H38' },
            { name: 'Laia', lastname: 'Alentorn', slackId: 'UND5T3Y21' },
            { name: 'Gonzalo', lastname: 'Roca', slackId: 'UJRCTDWCA' },
            { name: 'Jordi', lastname: 'Ferré', slackId: 'U7TRNFMLY' },
            { name: 'Daniel', lastname: 'Vea', slackId: 'U01D92LPAJ1' },
            { name: 'Daniel', lastname: 'de Vargas', slackId: 'U061R65FXFE' },
            { name: 'Arnau', lastname: 'Crespo', slackId: 'U05VB1QBEP9' },
        ];

        const mentionUsers = (users) => {
            return users.map((user) => `<@${user.slackId}>`).join(' ');
        };

        const getUserInfo = async (userId) => {
            return usersData.find((user) => user.id === userId);
        };

        const generateNotificationString = async (userIds) => {
            const usersInfo = await Promise.all(userIds.map((userId) => getUserInfo(userId)));

            if (usersInfo.length === 1) {
                const user = usersInfo[0];
                const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
            } else if (usersInfo.length === 2) {
                const mentions = usersInfo.map((user) => {
                    const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                    return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
                });
                return mentions.join(' and ');
            } else {
                const lastUser = usersInfo.pop();
                const usersString = usersInfo.map((user) => {
                    const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
                    return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
                }).join(', ');
                const lastMention = mentionedUsers.find((mentionedUser) => mentionedUser.name === lastUser.name && mentionedUser.lastname === lastUser.lastName);
                const lastUserString = lastMention ? mentionUsers([lastMention]) : `${lastUser.name} ${lastUser.lastName}`;
                return `${usersString}, and ${lastUserString}`;
            }
        };

        const assignedUser = await getUserInfo(assignedId);
        var message = '';
        if (notificationIds.length === 0 && assignedId !== '') {
            const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === assignedUser.name && mentionedUser.lastname === assignedUser.lastName);
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mention ? mentionUsers([mention]) : `${assignedUser.name} ${assignedUser.lastName}`}.`;
        }
        if (assignedId === '' && notificationIds.length > 0) {
            const notificationString = await generateNotificationString(notificationIds);
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID} and concerns ${notificationString}.`;
        }
        if (assignedId !== '' && notificationIds.length > 0) {
            const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === assignedUser.name && mentionedUser.lastname === assignedUser.lastName);
            const notificationString = await generateNotificationString(notificationIds);
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mention ? mentionUsers([mention]) : `${assignedUser.name} ${assignedUser.lastName}`} and concerns ${notificationString}.`;
        }
        if (assignedId === '' && notificationIds.length === 0) {
            message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}.`;
        }
        postToSlack(message);
    }

    const saveEditedIssueOrIssues = async () => {
        if (editedIssue) {
            if (isMorethanOne && takeActionToAll) {
                try {
                    // Iterate through each issue in the group and prepare the updates
                    issueGroup.forEach((groupIssue) => {
                        saveEditedIssue(groupIssue);
                    });
                    if (isAssignedOrNotified && (editedIssue.Assigned.to !== '' || editedIssue.Notification.to.length > 0)) {
                        generateAndPostSlackMessage(editedIssue);
                        setIsAssignedOrNotified(false);
                    }
                }
                catch (error) {
                    console.error('Error updating the issue:', error);
                }
            } else {
                saveEditedIssue(editedIssue);
                if (isAssignedOrNotified && (editedIssue.Assigned.to !== '' || editedIssue.Notification.to.length > 0)) {
                    generateAndPostSlackMessage(editedIssue);
                    setIsAssignedOrNotified(false);
                }
            }
        }
    }

    const saveEditedIssue = async (currentIssue) => {
        if (!editedIssue) {
            setEditedIssue(null);
            closeEditIssue();
            return;
        }
    
        try {
            const issuePath = `${kamEyePath}/${currentIssue.id}`;
            const issueRef = ref(database, issuePath);
            let updatedIssueData = { ...editedIssue };
    
            // Handle assigned user logic
            if (editedIssue.Assigned.to !== currentIssue.Assigned.to) {
                let uniqueKey = `Assigned_${userName}_${userLastName}`;
                let assignedTimestamp;
    
                if (editedIssue.Assigned.to !== '') {
                    const userDocRef = doc(db3, 'users', editedIssue.Assigned.to);
                    const userDocSnapshot = await getDoc(userDocRef);
    
                    if (userDocSnapshot.exists()) {
                        const userData = userDocSnapshot.data();
                        const { firstName, lastName } = userData;
                        let num = 1;
                        uniqueKey = `Assigned_${firstName}_${lastName}`;
    
                        if (!updatedIssueData.timestamps.Assigned) {
                            updatedIssueData.timestamps.Assigned = {};
                        }
    
                        while (updatedIssueData.timestamps.Assigned[uniqueKey]) {
                            num++;
                            uniqueKey = `Assigned_${firstName}_${lastName}_${num}`;
                        }
                        assignedTimestamp = serverTimestamp();
                    } else {
                        console.log('Assigned user document not found.');
                        return;
                    }
                } else {
                    let num = 1;
                    uniqueKey = `Unassigned`;
    
                    if (!updatedIssueData.timestamps.Assigned) {
                        updatedIssueData.timestamps.Assigned = {};
                    }
    
                    while (updatedIssueData.timestamps.Assigned[uniqueKey]) {
                        num++;
                        uniqueKey = `Unassigned_${num}`;
                    }
                    assignedTimestamp = serverTimestamp();
                }
    
                updatedIssueData = {
                    ...updatedIssueData,
                    timestamps: {
                        ...updatedIssueData.timestamps,
                        Assigned: {
                            ...updatedIssueData.timestamps.Assigned,
                            [uniqueKey]: assignedTimestamp,
                        },
                    },
                };
            }
    
            // Handle the new message
            if (newMessage.trim() !== '') {
                let messageKey;
                let messageNumber = 1;
    
                if (!updatedIssueData.timestamps.messages) {
                    updatedIssueData.timestamps.messages = {};
                }
    
                messageKey = `${userName}_${userLastName}_${messageNumber}`;
                while (updatedIssueData.timestamps.messages[messageKey]) {
                    messageNumber++;
                    messageKey = `${userName}_${userLastName}_${messageNumber}`;
                }
    
                updatedIssueData.timestamps.messages[messageKey] = {
                    message: newMessage,
                    timestamp: serverTimestamp(),
                };
            }
    
            // Update issue data with the new timestamps and other fields
            updatedIssueData = {
                ...updatedIssueData,
                Issue: {
                    ...updatedIssueData.Issue,
                    status: editedIssue.Issue.status,
                    message: editedIssue.Issue.message,
                },
                Notification: {
                    to: editedIssue.Notification.to,
                    Automatic: false,
                    Status: editedIssue.Notification.to.length > 0 ? 'notified' : 'unnotified',
                },
            };
    
            // Save the updated issue data to Firestore
            await update(issueRef, updatedIssueData);
    
            // Clear state and close the edit issue modal
            setNewMessage('');
            setEditedIssue(null);
            closeEditIssue();
        } catch (error) {
            console.error('Error updating the issue:', error);
        }
    };

    const updateAssignedTo = (userId) => {
        if (editedIssue) {
            setEditedIssue({
                ...editedIssue,
                Assigned: {
                    to: userId,
                    Status: userId !== '' ? 'assigned' : 'unassigned',
                }
            });
        }
        setIsAssignedOrNotified(true);
    };

    const handleMultiSelectChange = (selectedOptions, actionMeta) => {
        setIsAssignedOrNotified(true);
        // Determine the field name based on actionMeta
        const name = actionMeta.name;

        // Determine the selected values
        const selectedValues = selectedOptions.map(option => option.value);

        // Destructure the field name to access the nested properties
        const [mainKey, subKey] = name.split('.');

        setEditedIssue((prevIssue) => ({
            ...prevIssue,
            [mainKey]: {
                ...prevIssue[mainKey],
                [subKey]: selectedValues,
                Status: selectedValues.length > 0 ? 'notified' : 'unnotified',
            },
        }));
    };

    const closeIssueOrIssues = async (currentIssue) => {
        if (isMorethanOne && takeActionToAll) {
            try {
                setShowProgressPopup(true);

                // Iterate through each issue in the group and prepare the updates
                for (let i = 0; i < issueGroup.length; i++) {
                    const groupIssue = issueGroup[i];
                    await closeIssue(groupIssue.id, i + 1, issueGroup.length);
                    // setCurrentIssueIndex(i + 1);
                }
            } catch (error) {
                console.error('Error closing and archiving issue:', error);
            } finally {
                setShowProgressPopup(false);
            }
        } else {
            closeIssue(currentIssue.id, 0, 0);
        }
    }

    const closeIssue = async (issueId, index, length) => {
        try {
            const issuePath = `${kamEyePath}/${issueId}`;
            const issueRef = ref(database, issuePath);
            const issueSnapshot = await get(issueRef);
            const issueData = issueSnapshot.val();

            let closedTimestamp;
            let uniqueKey = 'Closed';
            let num = 1;

            if (!issueData.timestamps.close) {
                issueData.timestamps.close = {};
            }

            while (issueData.timestamps.close[uniqueKey]) {
                num++;
                uniqueKey = `Closed_${num}`;
            }
            closedTimestamp = serverTimestamp();

            issueData.Issue.status = 'Closed';
            issueData.Assigned.Status = 'Closed';

            issueData.timestamps.close[uniqueKey] = closedTimestamp;
            await update(issueRef, issueData);
            if (isMorethanOne) {
                setCurrentIssueIndex(index);
                if (index === length) {
                    closeEditIssue();
                }
            } else {
                closeEditIssue();
            }
        } catch (error) {
            console.error('Error closing and archiving issue:', error);
        }
    };

    const reopenIssueOrIssues = async (currentIssue) => {
        if (isMorethanOne && takeActionToAll) {
            try {
                const batch = [];

                // Iterate through each issue in the group and prepare the updates
                issueGroup.forEach((groupIssue) => {
                    reopenIssue(groupIssue.id);
                });
            } catch (error) {
                console.error('Error closing and archiving issue:', error);
            }
        } else {
            reopenIssue(currentIssue.id);
        }
    }

    const reopenIssue = async (issueId) => {
        try {
            const issuePath = `${kamEyePath}/${issueId}`;
            const issueRef = ref(database, issuePath);
            const issueSnapshot = await get(issueRef);
            const issueData = issueSnapshot.val();

            let reopenTimestamp;
            let uniqueKey = 'Reopen';
            let num = 1;

            if (!issueData.timestamps.open) {
                issueData.timestamps.open = {};
            }

            while (issueData.timestamps.open[uniqueKey]) {
                num++;
                uniqueKey = `Reopen_${num}`;
            }
            reopenTimestamp = serverTimestamp();
            issueData.Issue.status = 'Open';
            issueData.Assigned.Status = 'Open';
            issueData.timestamps.open[uniqueKey] = reopenTimestamp;
            issueData.timestamps.open.Last_open = reopenTimestamp;

            await update(issueRef, issueData);
            closeEditIssue();
        } catch (error) {
            console.error('Error closing and archiving issue:', error);
        }
    }


      

    useEffect(() => {
        setEditedIssue(issue);
        if (takeActionToAll !== null) {
            if (confirmationAction === 'save') {
                saveEditedIssueOrIssues();
            } else if (confirmationAction === 'close') {
                closeIssueOrIssues(issue);
            } else if (confirmationAction === 'reopen') {
                reopenIssueOrIssues(issue);
            }
            setTakeActionToAll(null);
            setConfirmationAction(null);
        }
    }, [takeActionToAll]);

    return (
        <div className="container">
            {showConfirmationActionPopup && (
                <ConfirmationActionPopup action={confirmationAction} numberOfIssues={issueGroup.length} setTakeActionToAll={setTakeActionToAll} onShow={showConfirmationActionPopup} onClose={() => { setShowConfirmationActionPopup(false) }} />
            )}
            {showProgressPopup && (
                <ClosingProgressPopup show={showProgressPopup} currentIssue={currentIssueIndex} totalIssues={issueGroup.length} action="closing" />
            )}
            <div className="text-start mb-3">
                <div className="d-flex align-items-center">
                    <button
                    type="button"
                    className="btn btn-link ps-0 pe-2 mb-3"
                    onClick={() => { closeEditIssue() }}
                    >
                    <ArrowLeft style={{ width: '19px', height: '16px' }} />
                    </button>
                    <h5><strong>Unit {issue.Installation.Unit_ID} Issue | {issue.Installation.Center}</strong></h5>
                </div>
                {/* <div>
                    <table className="table main-background">
                        <thead>
                            <tr className="text-center">
                                <th><strong className="text-muted">INSTALLATION</strong></th>
                                <th><strong className="text-muted">VOL.WATER</strong></th>
                                <th><strong className="text-muted">VOL.URINE</strong></th>
                                <th><strong className="text-muted">NUM.USAGE</strong></th>
                                <th><strong className="text-muted">CLEANER</strong></th>
                                <th><strong className="text-muted">TEMP</strong></th>
                                <th><strong className="text-muted">HUMIDITY</strong></th>
                                <th><strong className="text-muted">FW VERSION</strong></th>
                                <th><strong className="text-muted">WIFI RSSI</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td>{issue.Installation.Unit_ID}</td>
                                <td>{issue.Installation.Vol_water}</td>
                                <td>{issue.Installation.Vol_urine}</td>
                                <td>{issue.Installation.Num_usage}</td>
                                <td>{issue.Installation.Cleaner}</td>
                                <td>{issue.Installation.Temp}</td>
                                <td>{issue.Installation.Humidity}</td>
                                <td>{issue.Installation.Fw_version}</td>
                                <td>{issue.Installation.Wifi_rssi}</td>
                            </tr>
                        </tbody>
                </table>
                </div> */}
            </div>
            <div className="row">
                <div className="col-4 pt-1">
                    <Form.Group className="text-start" controlId="editIssueStatus" style={{ marginBottom: '1.2rem' }}>
                        <Form.Label><strong>STATUS</strong></Form.Label>
                        <Form.Select
                            value={editedIssue?.Issue?.status || issue.Issue.status}
                            onChange={(e) => {
                                setEditedIssue({
                                    ...editedIssue,
                                    Issue: { ...editedIssue.Issue, status: e.target.value },
                                });
                            }}
                        >
                            <option value="Open">Open</option>
                            <option value="In progress">In progress</option>
                        </Form.Select>
                    </Form.Group>
                    <table className="table main-background">
                        <tbody>
                            <tr className="text-start">
                                <td><strong className="text-muted">{isMorethanOne ? "FIRST DATE" : "DATE"}</strong></td>
                                <td className="text-end text-muted">{formatTimestamp(issue.timestamps.open.Created_at).split(' ')[0]}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">TIME</strong></td>
                                <td className="text-end text-muted">{formatTimestamp(issue.timestamps.open.Created_at).split(' ')[1]}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">ORGANIZATION</strong></td>
                                <td className="text-end text-muted">{issue.Installation.Organization}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">CENTER</strong></td>
                                <td className="text-end text-muted">{issue.Installation.Center}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">DEVICE</strong></td>
                                <td className="text-end text-muted">{issue.Installation.Dev_type}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">ISSUE ORIGIN</strong></td>
                                <td className="text-end text-muted">{issue.Issue.ID[0] === "M" ? "Manual" : "Automatic"}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">ISSUE ID</strong></td>
                                <td className="text-end text-muted">{issue.Issue.ID}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">ISSUE</strong></td>
                                <td className="text-end text-muted" style={{ wordBreak: "break-word" }}>{issue.Issue.definition}</td>
                            </tr>
                            <tr className="text-start">
                                <td><strong className="text-muted">ISSUE TYPE</strong></td>
                                <td className="text-end text-muted">{issue.Issue.type}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-4 pt-1">
                    <Form.Group className="text-start" controlId="editAssignedTo">
                        <Form.Label><strong>NOTIFICATION TO</strong></Form.Label>
                        <Select
                            defaultValue={usersData
                                .filter(user => issue.Notification.to.includes(user.id))
                                .map(user => ({
                                    key: user.id,
                                    value: user.id,
                                    label: `${user.name} ${user.lastName}`
                                }))}
                            isMulti
                            name="Notification.to"
                            options={usersData.map((user) => ({
                                value: user.id,
                                label: `${user.name} ${user.lastName}`
                            }))}
                            onChange={handleMultiSelectChange}
                            className="basic-multi-select clear-background"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                    <Form.Group className="text-start mt-2" controlId="editNotificationTo">
                        <Form.Label><strong>ASSIGNED TO</strong></Form.Label>
                        <Form.Select
                            value={editedIssue?.Assigned?.to || issue.Assigned.to}
                            onChange={(e) => updateAssignedTo(e.target.value)}
                        >
                            <option value="">Select User</option>
                            {usersData.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.name} {user.lastName}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {issue.Installation.Unit_ID !== "Rhampholeon" && (
                        <MqttComponent place="issue" issue={issue} userRole={userRole} units={unitsData} devices={devicesData} />
                    )}
                </div>
                <div className="col-4 pt-1">
                <Form.Group className="text-start" controlId="editAssignedTo">
                    <Form.Label><strong>ACTIVITY</strong></Form.Label>
                    <table className="table main-background">
                        <tbody>
                            {
                                // Extract all entries with their timestamps into a single array
                                Object.entries(issue.timestamps)
                                    .flatMap(([key, value]) =>
                                        key === "messages"
                                            ? Object.entries(value).map(([messageKey, messageValue]) => ({
                                                timestamp: messageValue.timestamp,
                                                content: (
                                                    <React.Fragment key={messageKey}>
                                                        <tr>
                                                            <td>{messageKey}</td>
                                                            <td>{formatTimestamp(messageValue.timestamp)}</td>
                                                        </tr>
                                                        <tr className="last-element">
                                                            <td colSpan="2" className="message-cell text-muted" style={{ maxWidth: '200px', wordBreak: 'break-word', whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                                                                {messageValue.message}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }))
                                            : Object.entries(value).map(([subKey, timestamp]) => ({
                                                timestamp: timestamp,
                                                content: (
                                                    <tr className="last-element" key={subKey}>
                                                        <td>{subKey}</td>
                                                        <td>{formatTimestamp(timestamp)}</td>
                                                    </tr>
                                                )
                                            }))
                                    )
                                    // Sort the array by timestamps in ascending order
                                    .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                                    // Render the sorted array
                                    .map(entry => entry.content)
                            }
                        </tbody>
                    </table>
                    <Form.Group className="text-start mt-2" controlId="editIssueMessage">
                        <Form.Control
                            as="textarea"
                            rows="3"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                    </Form.Group>
                </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center flex-column">
                    <Button
                        variant="primary"
                        className=""
                        onClick={() => {
                            if (userRole !== "kamleon_viewer" && userRole !== "kamleon_editor") {
                                if (isMorethanOne) {
                                    setConfirmationAction('save');
                                    setShowConfirmationActionPopup(true);
                                } else {
                                    saveEditedIssueOrIssues();
                                }
                            }
                        }}
                    >
                        <strong>{isMorethanOne ? "SAVE ISSUE GROUP" : "SAVE"}</strong>
                    </Button>
                    {issue.Issue.status !== "Closed" ? (
                        <a
                            href="#"
                            className="btn btn-link text-danger"
                            onClick={() => {
                                if (userRole !== "kamleon_viewer" && userRole !== "kamleon_editor") {
                                    if (isMorethanOne) {
                                        setConfirmationAction('close');
                                        setShowConfirmationActionPopup(true);
                                    } else {
                                        closeIssueOrIssues(issue);
                                    }
                                }
                            }}
                        >
                            {isMorethanOne ? "Close issue group" : "Close issue"}
                        </a>
                    ) : (
                        <a
                            href="#"
                            className="btn btn-link text-danger"
                            onClick={() => {
                                if (isMorethanOne) {
                                    setConfirmationAction('reopen');
                                    setShowConfirmationActionPopup(true);
                                } else {
                                    reopenIssueOrIssues(issue);
                                }
                            }}
                        >
                            {isMorethanOne ? "Reopen issue group" : "Reopen"}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};